<template>
  <v-app>
    <navigation :color="color" :flat="flat" :onHomeClick="goToHome" :onAboutClick="goToAbout" :onFaqClick="goToFaq"
      :onRatesClick="goToRates" :onLoginClick="goToLogin" />
    <v-main class="pt-0">
      <home />
      <about />
      <faq />
      <rates />
      <contact />
    </v-main>
    <v-scale-transition>
      <v-btn fab v-show="fab" v-scroll="onScroll" dark fixed bottom right color="primary" @click="toTop">
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <foot_section />
  </v-app>
</template>

<style scoped>
.v-main {
  background-image: url("~@/assets/img/bgMain.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>

<script>
import navigation from "../../components/home/Navigation";
import foot_section from "../../components/home/Footer";
import home from "../../components/home/HomeSection";
import about from "../../components/home/AboutSection";
import faq from "../../components/home/FAQSection";
import rates from "../../components/home/RatesSection";
import contact from "../../components/home/ContactSection";

export default {
  name: "App",

  components: {
    navigation,
    foot_section,
    home,
    about,
    faq,
    rates,
    contact,
  },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "primary";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "homeHeaderAccent";
        this.flat = false;
      } else {
        this.color = "primary";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    goToHome() {
      this.$vuetify.goTo("#hero");
    },
    goToAbout() {
      this.$vuetify.goTo("#about_us_1");
    },
    goToFaq() {
      this.$vuetify.goTo("#faq");
    },
    goToRates() {
      this.$vuetify.goTo("#rates");
    },
    goToLogin() {
      this.$router.push({ name: "login" });
    }
  },
};
</script>